var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "blacklist" },
    [
      _vm.tableData.length
        ? [
            _c(
              "div",
              { staticClass: "blacklist__table" },
              [
                _c(
                  "div",
                  { staticClass: "tableItem blacklist__table-choiceAll" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "tableItem__checkBox",
                        attrs: { indeterminate: _vm.isIndeterminate },
                        on: { change: _vm.handleCheckAllChange },
                        model: {
                          value: _vm.checkAll,
                          callback: function ($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "tableItem__content-title data__title",
                          },
                          [_vm._v(" Выбрать все ")]
                        ),
                      ]
                    ),
                    _vm.checkedBlacklists.length > 1
                      ? _c(
                          "div",
                          {
                            staticClass: "tableItem__removeItems",
                            on: {
                              click: function ($event) {
                                return _vm.handleTableMenu(
                                  "controlTableRemoveSelectedItems"
                                )
                              },
                            },
                          },
                          [_vm._v(" Удалить выбранные ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-checkbox-group",
                  {
                    on: { change: _vm.handleCheckedChange },
                    model: {
                      value: _vm.checkedBlacklists,
                      callback: function ($$v) {
                        _vm.checkedBlacklists = $$v
                      },
                      expression: "checkedBlacklists",
                    },
                  },
                  _vm._l(_vm.tableData, function (item, index) {
                    return _c(
                      "el-checkbox",
                      {
                        key: index,
                        staticClass: "tableItem",
                        attrs: { label: item },
                      },
                      [
                        _c("div", { staticClass: "tableItem__content" }, [
                          _c(
                            "div",
                            { staticClass: "tableItem__content-data" },
                            [
                              _c("div", [
                                _c("div", { staticClass: "data__date" }, [
                                  _c(
                                    "div",
                                    { staticClass: "data__date-title" },
                                    [_vm._v("Создана:")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "data__date-value" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getFormattedTzDate(
                                              item.created_at,
                                              _vm.dateFormat
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "data__date" }, [
                                  _c(
                                    "div",
                                    { staticClass: "data__date-title" },
                                    [_vm._v("Изменена:")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "data__date-value" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getFormattedTzDate(
                                              item.updated_at,
                                              _vm.dateFormat
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              !_vm.isViewer && !_vm.isSupport
                                ? _c(
                                    "div",
                                    { staticClass: "data-buttons" },
                                    [
                                      _c("IconPenMobile", {
                                        on: {
                                          onClick: function ($event) {
                                            return _vm.handleEdit(item)
                                          },
                                        },
                                      }),
                                      _c("IconTrashMobile", {
                                        on: {
                                          onClick: function ($event) {
                                            return _vm.handleDelete(item)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticClass: "truck" }, [
                            _c("div", { staticClass: "truck__title" }, [
                              _vm._v("Номер авто:"),
                            ]),
                            _c("div", { staticClass: "truck__value" }, [
                              _vm._v(_vm._s(item.plate_truck)),
                            ]),
                          ]),
                          _c("div", { staticClass: "reason" }, [
                            _vm._v(_vm._s(item.reason)),
                          ]),
                          _c("div", { staticClass: "properties" }, [
                            _c("div", { staticClass: "properties__item" }, [
                              _c(
                                "div",
                                { staticClass: "properties__item-title" },
                                [_vm._v("Срок действия:")]
                              ),
                              _c(
                                "div",
                                { staticClass: "properties__item-value" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.blocked_until
                                          ? _vm.blacklistDateBrief(
                                              item.blocked_until
                                            )
                                          : "Бессрочно"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "properties__item" }, [
                              _c(
                                "div",
                                { staticClass: "properties__item-title" },
                                [_vm._v("Добавил:")]
                              ),
                              _c(
                                "div",
                                { staticClass: "properties__item-value" },
                                [_vm._v(" " + _vm._s(item.blocked_by) + " ")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "containerMobile" },
              [
                _c("BottomToolbarButton", {
                  attrs: { title: "Добавить авто в ЧС" },
                  on: {
                    "button-clicked": function ($event) {
                      return _vm.handleTableMenu("controlTableAddItem")
                    },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.tableData.length && !_vm.isLoading
        ? _c("TableEmpty", {
            attrs: {
              warning:
                "У вас не добавлено ни одного автомобиля в черный список",
              proposition: "Добавить авто в ЧС",
            },
            on: {
              add: function ($event) {
                return _vm.handleTableMenu("controlTableAddItem")
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }